// https://auth0.com/blog/securing-gatsby-with-auth0/
import auth0 from "auth0-js"
import { navigate } from "gatsby"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
    ? new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
        redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
        responseType: "token id_token",
        scope: "openid profile email",
        })
    : {}

const tokens = {
    accessToken: false,
    idToken: false,
    //expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
    if (!isBrowser) {
        return
    }

    return localStorage.getItem("isLoggedIn") === "true"
}

export const login = () => {
    if (!isBrowser) {
        return
    }

    auth.authorize()
}

const setSession = (cb = () => {}) => (err, authResult) => {
    if (err) {
        navigate("/")
        cb()
        return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
        //let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
        tokens.accessToken = authResult.accessToken
        tokens.idToken = authResult.idToken
        //tokens.expiresAt = expiresAt
        user = authResult.idTokenPayload
        localStorage.setItem("isLoggedIn", true)
        navigate("/products")
        cb()
    }
}

export const silentAuth = callback => {
    if (!isAuthenticated()) return callback()
    auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = () => {
    if (!isBrowser) {
        return
    }

    auth.parseHash(setSession())
}

export const getProfile = () => {
    return user
}

export const logout = () => {
    localStorage.setItem("isLoggedIn", false)
    auth.logout({
        returnTo: window.location.origin
    })
}

/*
// https://github.com/jlengstorf/gatsby-auth0-app
import auth0 from "auth0-js"
import { navigate } from "gatsby"

export const isBrowser = typeof window !== "undefined"

const tokens = {
    idToken: false,
    accessToken: false,
}

let user = {}

export const isAuthenticated = () => {
    return tokens.idToken !== false
}

const auth = isBrowser
    ? new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
        redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
        responseType: "token id_token",
        scope: "openid profile email",
    })
    : {}

export const login = () => {
    if (!isBrowser) {
        return
    }

    auth.authorize()
}

export const logout = () => {
    tokens.accessToken = false
    tokens.idToken = false
    user = {}
    window.localStorage.setItem("isLoggedIn", false)
    console.log('logout setItem isLoggedIn false');

    auth.logout({
        returnTo: window.location.origin,
    })
}

const setSession = (cb = () => {}) => (err, authResult) => {
    if (err) {
        if (err.error === "login_required") {
            login()
        }
    }
    if (authResult && authResult.accessToken && authResult.idToken) {
        tokens.idToken = authResult.idToken
        tokens.accessToken = authResult.accessToken

        auth.client.userInfo(tokens.accessToken, (_err, userProfile) => {
            user = userProfile
            window.localStorage.setItem("isLoggedIn", true)
            console.log('setSession setItem isLoggedIn true');
            //navigate("/products")
            cb()
        })
    }
}

export const checkSession = callback => {
    const isLoggedIn = window.localStorage.getItem("isLoggedIn")
    if (isLoggedIn === "false" || isLoggedIn === null) {
        console.log('checkSession callback');
        callback()
    }
    const protectedRoutes = [`/products`, `/account`, `/callback`];
    const isProtectedRoute = protectedRoutes
        .map(route => window.location.pathname.includes(route))
        .some(route => route)
    if (isProtectedRoute) {
        auth.checkSession({}, setSession(callback))
    }
}

export const handleAuthentication = () => {
    auth.parseHash(setSession())
}

export const getProfile = () => {
    return user
}*/