/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "typeface-raleway";

import React from "react"
import { silentAuth } from "./src/utils/auth"
import SimpleReactLightbox from 'simple-react-lightbox'

class SessionCheck extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        loading: true,
        }
    }

    handleCheckSession = () => {
        this.setState({ loading: false })
    }

    componentDidMount() {
        silentAuth(this.handleCheckSession)
    }

    render() {
        return (
            this.state.loading === false && (
                <React.Fragment>{this.props.children}</React.Fragment>
            )
        )
    }
}

export const wrapRootElement = ({ element }) => {
    return <SessionCheck><SimpleReactLightbox>{element}</SimpleReactLightbox></SessionCheck>
}
/*

import React, { useState, useEffect } from "react"
import { checkSession } from "./src/utils/auth"

const SessionCheck = ({ children }) => {
  const [loading, stillLoading] = useState(true);
  useEffect(() => checkSession(() => stillLoading(false)));
  return loading === false && <>{children}</>
};

export const wrapRootElement = ({ element }) => (
  <SessionCheck>{element}</SessionCheck>
);*/